<script lang="ts" setup>
import { useDark } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { watch } from 'vue'
import NavigationDrawer from '@/components/navigationDrawer/navigationDrawer.vue'
import BAlert from '@/components/common/BAlert.vue'
import { useUserStore } from '@/config/store/userStore'
import { useApplicationStore } from '@/config/store/applicationStore'
import { resourceApi } from '@/services/api'
import { setFirmThemeColor } from '@/services/application'

useDark()
const route = useRoute()

const userStore = useUserStore()
const applicationStore = useApplicationStore()
if (userStore.user) {
  resourceApi.getConnectedResource().promise.then((resource) => {
    userStore.user = resource
    setFirmThemeColor(userStore.user.firm)
  }).catch(err => console.log('refresh user error', err))

  setFirmThemeColor(userStore.user.firm)
}

const removeError = (error) => {
  applicationStore.removeError(error)
}

if (!userStore.user) {
  const {
    needRefresh,
    updateServiceWorker,
  } = useRegisterSW()

  watch(needRefresh, () => {
    if (needRefresh.value)
      updateServiceWorker()
  })
}
</script>

<template>
  <div
    class="relative h-full min-h-screen transition-all"
    :class="[userStore.user ? !applicationStore.drawer.fixed ? 'md:pl-14' : 'md:pl-64' : undefined]"
  >
    <NavigationDrawer v-if="userStore.user" class="print:hidden" />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <Suspense>
          <component :is="Component" :key="route.path" />
        </Suspense>
      </transition>
    </router-view>

    <Teleport to="body">
      <div class="fixed bottom-6 right-6 z-100">
        <transition-group name="slide-x">
          <BAlert v-for="error in applicationStore.errors" :key="error.id" :alert="error" shadow class="py-1" @close="removeError" />
        </transition-group>
      </div>
    </Teleport>
  </div>
</template>

<style>
</style>
