import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import type { TColors } from '@/services/application/interfaces'

type tailwindColors = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 950

type IError = {
  id?: string
  title?: string
  message?: string
  color: TColors
  duration?: number
}
type IState = {
  errors: Array<IError>
  drawer: {
    fixed: boolean
  }
  theme?: {
    colors: {
      hsl: {
        [tailwindColors: number | string]: string
      }
      hex: {
        [tailwindColors: number | string]: string
      }
    }
  }
}

export const useApplicationStore = defineStore('application', {
  // arrow function recommended for full type inference
  state: (): IState => {
    return {
      errors: [
      ],
      drawer: {
        fixed: true,
      },
      theme: undefined,
    }
  },
  actions: {
    removeError(error: { id: string }) {
      const index = this.errors.findIndex(currentError => currentError.id === error.id)
      this.errors.splice(index, 1)
    },
    pushError({ color = 'error', duration = 3000, ...error }: IError) {
      const id = `${new Date().getTime()}`
      this.errors.push({ ...error, id, color, duration })
    },
    resetErrors() {
      this.errors = []
    },
  },
  persist: true,
})
